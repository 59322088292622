import React, { useContext } from "react";
import { styled } from "@mui/material/styles";
import { toJS } from "mobx";
import { useLocalObservable, observer } from "mobx-react-lite";
import { useRouter } from "found";

// Typings
// import { EditRendering_rendering } from '../renderings/__generated__/EditRendering_rendering.graphql';
// import { NewRendering_template, TemplateFieldKindEnum } from '../renderings/__generated__/NewRendering_template.graphql';

// Material-ui
import { Divider, Grid, Button } from "@mui/material";

// Components
import FieldContainer from "./FieldContainer";
import { createFormStore, initFieldsTree } from "./formHelpers";

export type TSubmissionFormContext = {
  store: any;
};

export const SubmissionFormContext =
  React.createContext<TSubmissionFormContext | null>(null);
export const useSubmissionFormContext = () =>
  useContext(SubmissionFormContext)!;

const PREFIX = "SubmissionForm";

const classes = {
  cancelButton: `${PREFIX}-cancelButton`,
  submitButton: `${PREFIX}-submitButton`,
  divider: `${PREFIX}-divider`,
};

const StyledSubmissionFormContextProvider = styled(
  SubmissionFormContext.Provider
)(({ theme }) => ({
  [`& .${classes.cancelButton}`]: {
    marginRight: "20px",
    border: "1px solid #d6d6d6",
    width: "86px",
    color: "#006ecc",
  },

  [`& .${classes.submitButton}`]: {
    width: "99px",
    backgroundColor: "#1e9bd9",
  },

  [`& .${classes.divider}`]: {
    margin: "40px 0",
  },
}));

export type Props = {
  templateVersion?: any;
  rendering?: any;
  onSubmit?: (input: any) => Promise<any>;
  locale?: string | null;
};

interface IObjectKeys {
  [key: string]: {
    cancel: string; // Cancel button text
    submit: string; // Submit button text
  };
}

const buttonStrings: IObjectKeys = {
  "en-US": {
    cancel: "Cancel",
    submit: "Submit",
  },
  "ja-JP": {
    cancel: "キャンセル",
    submit: "申請",
  },
};

const getSubmitButtonText = (language: string) => {
  return buttonStrings[language].submit;
};

const getCancelButtonText = (language: string) => {
  return buttonStrings[language].cancel;
};

//#region Component
const SubmissionForm = observer(
  ({ templateVersion, rendering, onSubmit, locale }: Props) => {
    const { router } = useRouter();

    const store = useLocalObservable(() =>
      createFormStore({
        tree: initFieldsTree(
          templateVersion.fields,
          rendering?.renderingFields
        ),
      })
    );

    const submitForm = async () => {
      store.inProgress = true;
      store.forceShowErrors = true;
      const variables = {
        renderingFields: Object.values(store.editableFieldsByKey).map(
          (f: any) => toJS(f.value)
        ),
      };

      if (store.valid) {
        // Not sure if we will want to do anything with the response
        // Error handling here or on new/edit?
        if (onSubmit) {
          // const result =
          await onSubmit(variables);
          // Do Stuff...
        }
      } else {
        // do other stuff [ch ]
        const firstInvalid = store.firstInvalid as { ref: HTMLElement };
        if (firstInvalid) {
          firstInvalid.ref.scrollIntoView({
            behavior: "smooth",
          });
        }
      }
      store.inProgress = false;
    };

    return (
      <StyledSubmissionFormContextProvider value={{ store }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <FieldContainer
                field={{ fields: store.fieldsTree }}
                forceShowErrors={store.forceShowErrors}
                disabled={store.inProgress}
                onChange={store.updateField}
                setValidity={store.updateValidity}
              />
            </Grid>
            <Divider className={classes.divider} />
            <Grid container spacing={3} sx={{ paddingTop: "1rem" }}>
              <Grid item xs={12} sm={9}>
                <Button onClick={() => router.go(-1)} color="grey">
                  {getCancelButtonText(locale || "en-US")}
                </Button>

                <Button
                  onClick={() => {
                    submitForm();
                  }}
                  disabled={store.inProgress}
                >
                  {getSubmitButtonText(locale || "en-US")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </StyledSubmissionFormContextProvider>
    );
  }
);

export default SubmissionForm;
