/**
 * @generated SignedSource<<b90199b273995c89d6c6b7c3536a1fed>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type TemplateRenderingKindEnum = "BATCH_CONFIGURATION" | "BATCH_DEFAULTS" | "STANDARD_RENDERING" | "%future added value";
export type TemplateRenderingTransitionsEnum = "APPROVE" | "ARCHIVE" | "DENY" | "MOVE_TO_IN_REVIEW" | "RELEASE" | "RENDER" | "SUBMIT_FOR_APPROVAL" | "%future added value";
export type TemplateRenderingCreateInput = {
  accessTokenKey?: string | null | undefined;
  campaignIds?: ReadonlyArray<string> | null | undefined;
  data?: any | null | undefined;
  kind?: TemplateRenderingKindEnum | null | undefined;
  name: string;
  renderingFields?: ReadonlyArray<TemplateRenderingFieldCreateInput> | null | undefined;
  renderingOutputs?: ReadonlyArray<TemplateRenderingOutputCreateInput> | null | undefined;
  templateVersionId: string;
  transition?: TemplateRenderingTransitionsEnum | null | undefined;
};
export type TemplateRenderingFieldCreateInput = {
  asset?: AssetCreateInput | null | undefined;
  data?: any | null | undefined;
  templateFieldId: string;
};
export type AssetCreateInput = {
  defaultResource?: boolean | null | undefined;
  existingAssetId?: string | null | undefined;
  file?: string | null | undefined;
  languageId?: string | null | undefined;
  metaData?: any | null | undefined;
  name?: string | null | undefined;
  public?: boolean | null | undefined;
};
export type TemplateRenderingOutputCreateInput = {
  templateOutputId: string;
};
export type createRenderingMutation$variables = {
  input: TemplateRenderingCreateInput;
};
export type createRenderingMutation$data = {
  readonly createPublicRendering: {
    readonly errors: ReadonlyArray<{
      readonly message: string;
    }>;
    readonly saved: boolean;
  };
};
export type createRenderingMutation = {
  response: createRenderingMutation$data;
  variables: createRenderingMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateRenderingPayload",
    "kind": "LinkedField",
    "name": "createPublicRendering",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "saved",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createRenderingMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createRenderingMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "c080a84df0dce90a31c6153597c2ab74",
    "id": null,
    "metadata": {},
    "name": "createRenderingMutation",
    "operationKind": "mutation",
    "text": "mutation createRenderingMutation(\n  $input: TemplateRenderingCreateInput!\n) {\n  createPublicRendering(input: $input) {\n    saved\n    errors {\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d185c5db3a7e9f8a236857db30c259d4";

export default node;
