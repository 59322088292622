import React from "react";
import { styled } from "@mui/material/styles";
import {
  Container,
  Typography,
  Link as LinkOut,
  List,
  ListItem,
} from "@mui/material";
const PREFIX = "Landing";

const classes = {
  title: `${PREFIX}-title`,
  list: `${PREFIX}-list`,
};

const REACT_APP_MUSIC_VIP_SIGNUP_URL = process.env.REACT_APP_MUSIC_VIP_SIGNUP_URL || "";
const REACT_APP_TV_VIP_SIGNUP_URL = process.env.REACT_APP_TV_VIP_SIGNUP_URL || "";
const REACT_APP_BOOK_VIP_SIGNUP_URL = process.env.REACT_APP_BOOK_VIP_SIGNUP_URL || "";

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.title}`]: {
    marginTop: "50px",
    marginBottom: "55px",
    textAlign: "center",
  },

  [`& .${classes.list}`]: {
    listStyleType: "disc !important",
    "& li": {
      display: "list-item",
      marginLeft: theme.spacing(4),
    },
  },
}));

interface Props {
  prop?: any;
}

const Landing = ({ prop }: Props) => {
  return (
    <Root>
      <Container maxWidth="md">
        <Typography className={classes.title} variant="h1">
          Apple Services Performance Partners Program
        </Typography>
      </Container>
      <Container style={{ maxWidth: "70ch" }}>
        <Typography variant="body1">
          Our program is only open to partners who have content on one of the
          Apple Media Stores and by invitation to a limit number of other
          partners.
        </Typography>

        <br />

        <Typography variant="body1">
          If you are a record label, music artist, movie studio, or book
          author/publisher, please apply below:
        </Typography>

        <List className={classes.list}>
          <ListItem>
            <LinkOut href={REACT_APP_MUSIC_VIP_SIGNUP_URL}>
              Record label or artist
            </LinkOut>
          </ListItem>
          <ListItem>
            <LinkOut href={REACT_APP_TV_VIP_SIGNUP_URL}>
              Movie or tv studio
            </LinkOut>
          </ListItem>
          <ListItem>
            <LinkOut href={REACT_APP_BOOK_VIP_SIGNUP_URL}>
              Book author or publisher
            </LinkOut>
          </ListItem>
        </List>

        <Typography variant="body1">
          To learn more about the Partner Program, visit our{" "}
          <LinkOut href="https://performance-partners.apple.com/home">
            Affiliate Resources Site.
          </LinkOut>
        </Typography>
      </Container>
    </Root>
  );
};

export default Landing;
