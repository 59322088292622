import React from "react";
import { makeRouteConfig, Route } from "found";

import { Container } from "@mui/material";
import Landing from "../components/Landing";
import ErrorPage from "../components/ErrorPage";
import OtherPartnerPage from "../components/OtherPartnersPage";
import ContentPartnerPage from "../components/ContentPartnerPage";
import VIPPartnerPage from "../components/VIPPartnerPage";
import NewSubmission from "../components/NewSubmission";
import Layout from "../components/Layout";
import ApplicationReceived from "../components/ApplicationReceived";
import LoadingPage from "../components/LoadingPage";
import {
  otherTemplates,
  contentTemplate,
  otherTemplate,
  vipTemplate,
  japaneseTemplate,
} from "../graphQL/queries/formTemplate";
import JapanesePartnerPage from "../components/JapanesePartnerPage";

function renderErrorOrLoading(error: any, noLoading?: boolean) {
  if (error) {
    return (
      <Container maxWidth="md" style={{ padding: "0 20px" }}>
        <ErrorPage status={error.status || 500} />
      </Container>
    );
  }
  return noLoading ? undefined : <LoadingPage />;
}

export default makeRouteConfig(
  <Route path="/" Component={Layout}>
    <Route
      render={() => {
        return (
          <Container maxWidth="lg" style={{ padding: "0 20px" }}>
            <Landing />
          </Container>
        );
      }}
    />

    <Route
      path="app-received"
      render={({ error, match }) => {
        if (error) return renderErrorOrLoading(error);

        return (
          <Container maxWidth="lg" style={{ padding: "0 20px" }}>
            <ApplicationReceived
              templateSlug={match?.location?.state?.templateSlug}
              showVipMessage={Boolean(match?.location?.state?.showVipMessage)}
              showTapToPayMessage={Boolean(
                match?.location?.state?.showTapToPayMessage
              )}
            />
          </Container>
        );
      }}
    />

    <Route path="other-partners">
      <Route
        query={otherTemplates}
        Component={OtherPartnerPage}
        prepareVariables={(params: any) => {
          return {
            templateTypes: ["FORM"],
            ...params,
          };
        }}
        render={({ error, props, variables }: any) => {
          if (error || !props) return renderErrorOrLoading(error);
          return (
            <Container maxWidth="md" style={{ padding: "0 20px" }}>
              <OtherPartnerPage query={props} variables={variables} />
            </Container>
          );
        }}
      />

      <Route
        path=":slug"
        query={otherTemplate}
        Component={NewSubmission}
        render={({ error, props, match }: any) => {
          if (error || !props) return renderErrorOrLoading(error);
          return (
            <Container maxWidth="md" style={{ padding: "0 20px" }}>
              <NewSubmission
                router={match.router}
                tagName={props.template.tag?.name}
                version={props.template.version}
              />
            </Container>
          );
        }}
      />
    </Route>

    <Route path="content-partners">
      <Route
        query={contentTemplate}
        Component={ContentPartnerPage}
        render={({ error, props, match }: any) => {
          if (error || !props) return renderErrorOrLoading(error);
          return (
            <Container maxWidth="md" style={{ padding: "0 20px" }}>
              <ContentPartnerPage
                router={match.router}
                template={props.template}
              />
            </Container>
          );
        }}
      />
    </Route>

    <Route path="japanese-partners">
      <Route
        query={japaneseTemplate}
        Component={JapanesePartnerPage}
        render={({ error, props, match }: any) => {
          if (error || !props) return renderErrorOrLoading(error);
          return (
            <Container maxWidth="md" style={{ padding: "0 20px" }}>
              <JapanesePartnerPage
                router={match.router}
                template={props.template}
              />
            </Container>
          );
        }}
      />
    </Route>

    <Route path="vip">
      <Route
        path=":key"
        query={vipTemplate}
        Component={VIPPartnerPage}
        render={({ error, props, match }: any) => {
          if (error || !props) return renderErrorOrLoading(error);
          return (
            <Container maxWidth="md" style={{ padding: "0 20px" }}>
              <VIPPartnerPage
                router={match.router}
                templateToken={props.templateToken}
              />
            </Container>
          );
        }}
      />
    </Route>

    <Route
      path="*"
      render={() => {
        return (
          <Container maxWidth="md" style={{ padding: "0 20px" }}>
            <ErrorPage status={404} />
          </Container>
        );
      }}
    />
  </Route>
);
