import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Grid,
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { fetchQuery } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import { useRelayEnvironmentContext } from "../config/RelayEnvironmentProvider";
import { ApplicationReceivedQuery } from "./__generated__/ApplicationReceivedQuery.graphql";
import LoadingPage from "./LoadingPage";

const PREFIX = "ApplicationReceived";

const classes = {
  title: `${PREFIX}-title`,
  center: `${PREFIX}-center`,
};

const StyledContainer = styled(Container)(({ theme }) => ({
  [`& .${classes.title}`]: {
    marginTop: "50px",
    marginBottom: "30px",
    textAlign: "center",
  },

  [`& .${classes.center}`]: {
    textAlign: "center",
  },

  ul: {
    margin: 0,
    paddingLeft: "1rem",
    listStyleType: "disc",
  },

  li: {
    marginBottom: ".4rem",
  },

  a: {
    display: "inline-block",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    verticalAlign: "middle",
    textDecoration: "none",
  },

  "& .list-url": {
    margin: "-.2rem 0 0 .2rem",
    maxWidth: "300px",
  },
  "& .url": {
    maxWidth: "100%",
  },
}));

type Country = {
  name: string;
  url: string | Record<string, string> | null;
  password: string | null;
};

type ApplicationReceivedProps = {
  templateSlug: string;
  showVipMessage?: boolean;
  showTapToPayMessage?: boolean;
};

const ApplicationReceived = ({
  templateSlug,
  showVipMessage,
  showTapToPayMessage,
}: ApplicationReceivedProps) => {
  const { environment } = useRelayEnvironmentContext();
  const [templateData, setTemplateData] = useState<any | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTemplateData = async () => {
      if (!environment) {
        console.warn("No Relay environment available!");
        setLoading(false);
        return;
      }

      try {
        const data = await fetchQuery<ApplicationReceivedQuery>(
          environment,
          graphql`
            query ApplicationReceivedQuery($slug: String!) {
              template(slug: $slug) {
                name
                version {
                  data
                }
              }
            }
          `,
          { slug: templateSlug }
        ).toPromise();

        setTemplateData(data?.template?.version?.data || null);
      } catch (error) {
        console.error("Error fetching template data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTemplateData();
  }, [environment, templateSlug]);

  if (loading) {
    return <LoadingPage />;
  }

  const countries: Country[] = templateData?.additional_affiliate_data || [];

  return (
    <StyledContainer maxWidth="md">
      {showTapToPayMessage ? (
        <>
          <Grid item xs={12}>
            <Typography className={classes.title} variant="h1">
              Thank you for your submission.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography className={classes.center} variant="h3">
              In order to download the required assets, please click the link
              for the desired market and use the corresponding password for
              access.
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: "1rem" }}>
            <Typography className={classes.center} variant="h3">
              Ensure you provide your Marketing team with the Tap to Pay on
              iPhone Marketing Toolkit once downloaded.
            </Typography>
          </Grid>

          <Grid item xs={12} sx={{ marginTop: "1rem" }}>
            <Typography className={classes.center} variant="h3">
              Please do not share this page or its content publicly.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <TableContainer
              component={Paper}
              sx={{
                width: "100%",
                margin: "0 auto",
                boxShadow: "none",
                border: "1px solid #e0e0e0",
              }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold", width: "20%" }}>
                      Market
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold", width: "60%" }}>
                      URL
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold", width: "20%" }}>
                      Password
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {countries.map((country, index) => (
                    <TableRow key={index}>
                      <TableCell sx={{ verticalAlign: "top" }}>
                        {country.name}
                      </TableCell>
                      <TableCell sx={{ verticalAlign: "top" }}>
                        {typeof country.url === "string" ? (
                          <a
                            href={country.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            title={country.url}
                            className="url"
                          >
                            {country.url}
                          </a>
                        ) : country.url && typeof country.url === "object" ? (
                          <ul>
                            {Object.entries(country.url).map(
                              ([description, link], idx) =>
                                typeof link === "string" && (
                                  <li key={idx}>
                                    <span>{description} — </span>
                                    <a
                                      href={link}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      title={link}
                                      className="list-url"
                                    >
                                      {link}
                                    </a>
                                  </li>
                                )
                            )}
                          </ul>
                        ) : null}
                      </TableCell>
                      <TableCell
                        sx={{ verticalAlign: "top", wordBreak: "break-word" }}
                      >
                        {country.password}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={12}>
            <Typography className={classes.title} variant="h1">
              Thank you for your interest in the Apple Services Performance
              Partners Program.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            {showVipMessage ? (
              <Typography className={classes.center} variant="h3">
                Your application has been received. We will get back to you in
                5-7 days.
              </Typography>
            ) : (
              <Typography className={classes.center} variant="h3">
                Your application has been received. If you qualify, you will
                hear back from us.
              </Typography>
            )}
          </Grid>
        </>
      )}
    </StyledContainer>
  );
};

export default ApplicationReceived;
