import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { createFragmentContainer, RelayProp } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import { VIPPartnerPage_templateToken$data } from "./__generated__/VIPPartnerPage_templateToken.graphql";
import ErrorPage from "./ErrorPage";
import NewSubmission from "../components/NewSubmission";
import {
  Grid,
  Typography,
  Link as LinkOut,
  // Dialog,
  // DialogActions,
  // DialogContent,
  // DialogTitle,
  // Button,
  // TextField,
  // CircularProgress,
  // Box,
} from "@mui/material";
// import DangerousIcon from "@mui/icons-material/Dangerous";
// import validateAccessTokenMutation from "../graphQL/mutations/validateAccessToken";
// import commitMutationPromise from "../graphQL/mutations/commitMutationPromise";
// import { useRelayEnvironmentContext } from "../config/RelayEnvironmentProvider";

const PREFIX = "VIPPartnerPage";

const classes = {
  root: `${PREFIX}-root`,
  divider: `${PREFIX}-divider`,
  opening: `${PREFIX}-opening`,
  requirements: `${PREFIX}-requirements`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.root}`]: {
    flexGrow: 1,
  },

  [`& .${classes.divider}`]: {
    margin: "40px 0",
  },

  [`& .${classes.opening}`]: {
    maxWidth: "800px",
    marginTop: "20px",
    fontSize: "21px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.48,
    letterSpacing: "normal",
    textAlign: "center",
  },

  [`& .${classes.requirements}`]: {
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.63,
    letterSpacing: "0.32px",
    color: "#444444",
  },
}));

interface Props {
  relay: RelayProp;
  templateToken: VIPPartnerPage_templateToken$data;
  router?: any;
  match?: any;
}

type ErrorCopy = {
  headline: string;
  message: string;
  ctaMessage: string;
  ctaLink?: string;
};

const VIPPartnerPage = ({ router, templateToken }: Props) => {
  // const { environment } = useRelayEnvironmentContext();

  const [errorCopy, setErrorCopy] = useState<ErrorCopy>();
  // const [password, setPassword] = useState("");
  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const [isAuthorized, setIsAuthorized] = useState(false);
  // const [isSubmitting, setIsSubmitting] = useState(false);

  // const [modalError, setModalError] = useState("");

  // const handlePasswordSubmit = () => {
  //   setIsSubmitting(true);
  //   setModalError("");
  //
  //   commitMutationPromise(environment, {
  //     mutation: validateAccessTokenMutation,
  //     variables: {
  //       input: {
  //         accessTokenKey: templateToken.key,
  //         passphrase: password,
  //       },
  //     },
  //   })
  //     .then((response: any) => {
  //       setIsSubmitting(false);
  //
  //       const isValid = response.validateAccessToken.result.valid;
  //       if (isValid) {
  //         setIsAuthorized(true);
  //         setIsModalOpen(false);
  //       } else {
  //         setModalError(
  //           "The password you entered was invalid. Please try again."
  //         );
  //       }
  //     })
  //     .catch(() => {
  //       setIsSubmitting(false);
  //       setModalError("Something went wrong. Please try again.");
  //     });
  // };

  useEffect(() => {
    const version = templateToken && templateToken.template.version;
    var error: ErrorCopy = {
      headline: "",
      message: "",
      ctaMessage: "",
      ctaLink: "",
    };

    if (templateToken && !version) {
      error.headline = "Page Not Found";
      error.message = "This page you requested could not be found.";
      error.ctaMessage =
        "If you are working with an Apple contact, reach out to them for an updated link. To apply for the Apple Services Performance Partners Program, visit: ";
      error.ctaLink = "https://partners.applemediaservices.com";
    } else {
      error.headline = "Link Has Expired";
      error.message =
        "The link for this application has either expired, or an application has already been submitted.";
      error.ctaMessage = "Reach out to your Apple contact for a new link.";
    }
    setErrorCopy(error);

    // Password protection disabled – automatically authorize
    // if (templateToken?.template?.tag?.name === "TTP VIP") {
    //   setIsAuthorized(false);
    //   setIsModalOpen(true);
    // } else {
    //   setIsAuthorized(true);
    // }
    // For now, always authorize
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateToken]);

  // Helper function to determine the opening message based on tag name
  const getOpeningMessage = (tagName: string | undefined) => {
    switch (tagName) {
      case "MLS VIP":
        return (
          <>
            Register of your invitation MLS program. Once approved, you'll get a
            link to our program on Partnerize where you can add your payment
            information and get your tracking token.
          </>
        );
      case "TTP VIP":
        return (
          <>
            Please complete the form below and follow the instructions to
            download the relevant Tap to Pay on iPhone Marketing Toolkit for
            your region or market.
            <br />
            <br />
            You should also ensure you provide your Marketing team with the Tap
            to Pay on iPhone Marketing Toolkit once downloaded.
          </>
        );
      default:
        return (
          <>
            Please answer a few questions below. Once we validate the details of
            your application, if we determine you are a good fit, we will
            contact you and provide further details.
          </>
        );
    }
  };

  return (
    <Root>
      {templateToken && templateToken.template.version ? (
        <>
          <NewSubmission
            templateSlug={templateToken.template.slug}
            router={router}
            tagName={templateToken.template.tag?.name}
            version={templateToken.template.version}
            accessToken={templateToken.key}
          >
            <Grid
              item
              xs={12}
              container
              justifyContent="center"
              sx={{ zIndex: "1000" }}
            >
              <Typography className={classes.opening}>
                {getOpeningMessage(templateToken.template.tag?.name)}
              </Typography>
              {templateToken.template.tag?.name !== "TTP VIP" && (
                <Typography variant="body1" className={classes.requirements}>
                  <br />
                  <span>
                    View the Apple Services Performance Partner Program terms
                    and conditions{" "}
                    <LinkOut href="https://apple.co/pp-partnerizetc">
                      here
                    </LinkOut>
                  </span>
                </Typography>
              )}
            </Grid>
          </NewSubmission>

          {/*
          <Dialog
            open={isModalOpen && !isAuthorized}
            onClose={() => null}
            disableEscapeKeyDown
            sx={{ padding: "16px" }}
          >
            <DialogTitle sx={{ marginBottom: "8px" }}>
              Password Required
            </DialogTitle>
            <DialogContent sx={{ padding: "8px 16px" }}>
              <TextField
                sx={{ margin: "6px 0 8px 0", minWidth: "400px" }}
                label="Password"
                type="password"
                fullWidth
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                autoFocus
              />
              <Box
                sx={{
                  minHeight: "24px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {modalError && (
                  <Typography
                    color="error"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: "14px",
                    }}
                  >
                    <DangerousIcon sx={{ mr: 1 }} />
                    {modalError}
                  </Typography>
                )}
              </Box>
            </DialogContent>
            <DialogActions
              sx={{
                justifyContent: "flex-end",
                minHeight: "40px",
                marginTop: "-12px",
              }}
            >
              <Button
                onClick={handlePasswordSubmit}
                color="primary"
                variant="contained"
                disabled={isSubmitting}
              >
                {isSubmitting ? <CircularProgress size={20} /> : "Submit"}
              </Button>
            </DialogActions>
          </Dialog>
          */}
        </>
      ) : (
        <ErrorPage
          customHeadline={errorCopy?.headline}
          customMessage={errorCopy?.message}
          ctaMessage={errorCopy?.ctaMessage}
          ctaLink={errorCopy?.ctaLink}
        />
      )}
    </Root>
  );
};

export default createFragmentContainer(VIPPartnerPage, {
  templateToken: graphql`
    fragment VIPPartnerPage_templateToken on TemplateToken {
      key
      template {
        id
        name
        type
        slug
        tag {
          name
        }
        version {
          id
          name
          description
          ...NewSubmission_version
        }
      }
    }
  `,
});
