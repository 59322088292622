import React, { useContext, useState, useEffect } from "react";
import {
  FetchFunction,
  Environment,
  Network,
  RecordSource,
  Store,
  RequestParameters,
  Variables,
  CacheConfig,
  UploadableMap,
} from "relay-runtime";
import { HttpError } from "found";
import { Resolver } from "found-relay";
import LoadingPage from "../components/LoadingPage";

const ENDPOINT_URL = process.env.REACT_APP_GRAPHQL_ENDPOINT || "";

function generateRequest(
  request: RequestParameters,
  variables: Variables,
  uploadables: UploadableMap | null
) {
  const requestConfig: RequestInit = {
    method: "POST",
    headers: {
      Accept: "application/json",
    },
  };

  if (uploadables) {
    if (!FormData)
      throw new Error("Uploading files without `FormData` not supported.");

    const formData = new FormData();
    if (request.text) formData.append("query", request.text);
    formData.append("variables", JSON.stringify(variables));

    Object.keys(uploadables).forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(uploadables, key)) {
        formData.append(`files[${key}]`, uploadables[key]);
      }
    });

    requestConfig.body = formData;
  } else {
    requestConfig.headers = {
      ...requestConfig.headers,
      "Content-Type": "application/json",
    };
    requestConfig.body = JSON.stringify({
      query: request.text,
      variables,
      "x-connect-team-id": "com.apple.bedrock.affiliate-registration",
    });
  }

  return new Request(ENDPOINT_URL, requestConfig);
}

export interface IRelayEnvironmentProvider {
  environment: Environment | undefined;
  resolver: any;
}

export const store = new Store(new RecordSource());

export const RelayEnvironmentContext =
  React.createContext<IRelayEnvironmentProvider | null>(null);
export const useRelayEnvironmentContext = () =>
  useContext(RelayEnvironmentContext)!;

export const RelayEnvironmentProvider = ({ children }: any) => {
  const [environment, setEnvironment] = useState<Environment>();
  const [resolver, setResolver] = useState<any>();

  useEffect(() => {
    const fetchQuery = async (
      request: RequestParameters,
      variables: Variables,
      cacheConfig: CacheConfig,
      uploadables: UploadableMap | null
    ) => {
      const fetchReq = generateRequest(request, variables, uploadables);
      //this is what displays an error
      const response = await fetch(fetchReq);

      const responseJson = await response.json();

      if (response.status === 404 || response.status >= 500) {
        throw new HttpError(response.status, responseJson);
      }

      return responseJson;
    };

    const environment = new Environment({
      network: Network.create(fetchQuery as FetchFunction),
      store: store,
    });
    setEnvironment(environment);
  }, []);

  useEffect(() => {
    if (environment) {
      setResolver(new Resolver(environment));
    } else {
      setResolver(undefined);
    }
  }, [environment]);

  return (
    <RelayEnvironmentContext.Provider
      value={{
        environment,
        resolver,
      }}
    >
      {resolver ? children : <LoadingPage />}
    </RelayEnvironmentContext.Provider>
  );
};
