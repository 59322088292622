/**
 * @generated SignedSource<<eec4d62d2ce35deb7acedf0ffcc1f7ab>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type TemplateFieldKindEnum = "ADDRESS" | "AFFILIATE_USERNAME" | "AM_ADAM" | "AM_ADAM_ARTWORK" | "AUDIO_VIDEO" | "CHECKBOX" | "CHECKBOX_GROUP" | "COLOR" | "COLOR_PALETTE" | "CONTAINER" | "COUNTRY_SELECT" | "DATETIME_FORMATTER" | "FIELDSET" | "FILE_UPLOADER" | "IMAGE" | "IMAGE_EFFECT" | "NESTED_ATTRIBUTES" | "PARTNER_LOGO" | "PARTNER_NAME" | "QR_CODE" | "RADIO_GROUP" | "RICH_TEXT" | "SELECT" | "SEPARATOR" | "SHARED_RESOURCE" | "SWITCH" | "TEXT" | "TEXT_AREA" | "UTS_BROWSE" | "UTS_PRODUCT" | "UTS_PRODUCT_BUTTON" | "UTS_SHELF" | "UTS_SHELF_ITEM" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type NewSubmission_version$data = {
  readonly description: string | null | undefined;
  readonly fields: ReadonlyArray<{
    readonly data: any | null | undefined;
    readonly displayOrder: number;
    readonly id: string;
    readonly key: string;
    readonly kind: TemplateFieldKindEnum;
    readonly name: string;
    readonly parentId: string | null | undefined;
  }>;
  readonly id: string;
  readonly name: string | null | undefined;
  readonly " $fragmentType": "NewSubmission_version";
};
export type NewSubmission_version$key = {
  readonly " $data"?: NewSubmission_version$data;
  readonly " $fragmentSpreads": FragmentRefs<"NewSubmission_version">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NewSubmission_version",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TemplateField",
      "kind": "LinkedField",
      "name": "fields",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "key",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "kind",
          "storageKey": null
        },
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "parentId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "data",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayOrder",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TemplateVersion",
  "abstractKey": null
};
})();

(node as any).hash = "c3d8aadc33d6cf5d5ec3291a847b5814";

export default node;
